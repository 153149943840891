.cardSearch {
}

.cardImage {
  position: relative;
  width: 102px;
  height: 108px;
  // min-width: calc((2 / 6) * 100vw - 24px);
  // max-width: 320px;
  // padding-top: 100%;

  @screen lg {
    width: 100%;
    height: auto;
    padding-top: calc(352 / 330 * 60%);
  }
}

.eventDate {
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0,0,0,0.5);
  color: #fff;
  border-radius: 4px;
  padding: 0.3rem 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.eventDateDay {
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 1;
}

.eventDateMonth {
  font-size: 0.9rem;
  margin-top: 0.1rem;
  text-transform: capitalize;
}

.cardContent {
  width: calc(100% - 100px);

  @screen lg {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
